export const PARTNERS_EMAIL = "support@tutor.peardeck.com";
export const UMBRELLA = "umbrella";
export const K12_RESOURCES =
  "https://sites.google.com/goguardian.com/pdt-adoption-resources/adoption-resources";
export const HIGHER_ED_RESOURCES =
  "https://sites.google.com/goguardian.com/pdt-higher-ed-resources/partner-resources?pli=1";

export const getResourcesLink = (partnerType?: string | null) => {
  return partnerType === "k-12" ? K12_RESOURCES : HIGHER_ED_RESOURCES;
};
