import { Menu, Transition } from "@headlessui/react";
import ArrowIcon from "@material-design-icons/svg/filled/keyboard_arrow_down.svg";
import SchoolIcon from "@material-design-icons/svg/outlined/school.svg";
import classnames from "classnames";
import { useRouter } from "next/router";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import Spinner from "~/components/core/Spinner/Index";
import { MINUTES_IN_HOUR } from "~/constants/dates";
import {
  UMBRELLA_BILLING_MODE,
  UmbrellaAccount
} from "~/declarations/models/UmbrellaAccount";
import { useAuthenticatedFetch } from "~/utils/http";
import { getSchoolYearStart } from "~/utils/schoolYearHelpers";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import ParentSchoolIcon from "../../../../../assets/icons/parentSchool.svg";
import {
  menuButtonClasses,
  menuItemClasses,
  menuItemsClasses,
  menuTransitionClasses
} from "./UserMenu";

export const UmbrellaMenu: React.FC = () => {
  const router = useRouter();
  const { user } = useRequiredAuthContext();
  const {
    selectedUmbrella,
    onSelectUmbrella,
    setSchoolYearStartDate
  } = useRequiredAuthContext();

  const handleClickSelectItem = (umbrellaAccount: UmbrellaAccount) => {
    onSelectUmbrella?.(umbrellaAccount);

    setSchoolYearStartDate(
      getSchoolYearStart(
        umbrellaAccount.schoolYearStartMonth - 1,
        umbrellaAccount.schoolYearStartDay
      )
    );

    // clear query params in case current/previous school year is selected- will change per umbrella
    const { pathname } = router;
    router.replace({ pathname, query: {} });

    trackEvent("change_umbrella_account", {
      umbrella: umbrellaAccount.umbrellaAccName
    });
  };

  const handleKeyPressSelectItem = (
    umbrellaAccount: UmbrellaAccount,
    e?: React.KeyboardEvent
  ) => {
    if (e?.key === "Enter" || e?.key === "Space") {
      onSelectUmbrella?.(umbrellaAccount);
      trackEvent("change_umbrella_account", {
        umbrella: umbrellaAccount.umbrellaAccName
      });
    }
  };

  if (user?.umbrellaAccounts?.length === 1) {
    return (
      <div className="bg-white rounded-[8px] h-full flex p-[16px] paragraph w-full rounded-r-none flex-col gap-[10px]">
        <div className="flex items-center gap-[8px]">
          <Icon parent={selectedUmbrella.displayName?.includes("Parent")} />
          <div className="truncate max-w-[290px]">
            {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName}
          </div>
        </div>
        <AccountBilling selectedUmbrella={selectedUmbrella} />
      </div>
    );
  }

  return (
    <Menu as="div" className="relative h-full w-[284px]">
      <MenuButton selectedUmbrella={selectedUmbrella} />

      <Transition as={React.Fragment} {...menuTransitionClasses}>
        <Menu.Items
          className={classnames(
            menuItemsClasses,
            "right-0 top-[48px] w-[284px] max-h-[400px] overflow-y-auto"
          )}
        >
          {user?.umbrellaAccounts.map(umbrella => (
            <Menu.Item
              as="button"
              key={umbrella.umbrellaAccName}
              onClick={() => handleClickSelectItem(umbrella)}
              onKeyPress={(e: React.KeyboardEvent) =>
                handleKeyPressSelectItem(umbrella, e)
              }
              data-testid={struct.adminDash.header.umbrellaMenu.umbrella(
                umbrella.displayName ?? umbrella.umbrellaAccName
              )}
            >
              {({ active }) => (
                <div
                  className={classnames(
                    menuItemClasses,
                    "text-left justify-start !items-start",
                    active ||
                      (umbrella.umbrellaAccName ===
                        selectedUmbrella.umbrellaAccName &&
                        "bg-cream-200")
                  )}
                >
                  <Icon parent={umbrella.displayName?.includes("Parent")} />
                  {displayName(
                    umbrella.displayName ?? umbrella.umbrellaAccName
                  )}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface IButtonProps {
  selectedUmbrella: UmbrellaAccount;
}

const MenuButton: React.FC<IButtonProps> = ({ selectedUmbrella }) => {
  return (
    <Menu.Button
      className={classnames(
        menuButtonClasses,
        "w-full rounded-r-none flex-col gap-[10px] justify-center"
      )}
      data-testid={struct.adminDash.header.umbrellaMenu.menuButton}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-[8px]">
          <Icon parent={selectedUmbrella.displayName?.includes("Parent")} />
          <div className="line-clamp-1 max-w-[290px] text-left">
            {displayName(
              selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName
            )}
          </div>
        </div>
        <ArrowIcon
          className="w-[24px] h-[24px] flex-shrink-0 fill-current ml-[4px]"
          aria-hidden
        />
      </div>
      <AccountBilling selectedUmbrella={selectedUmbrella} />
    </Menu.Button>
  );
};

const AccountBilling: React.FC<IButtonProps> = ({ selectedUmbrella }) => {
  const { data: hoursUsedRes, loading } = useAuthenticatedFetch(
    "/api/v1/new_stats/hours_used/",
    { umbrellaAccountId: selectedUmbrella.umbrellaAccName },
    true
  );

  const totalHours = selectedUmbrella.timeLimitMinutes / MINUTES_IN_HOUR;
  const usedHours = hoursUsedRes?.hoursUsed ?? 0;
  const remainingHours = totalHours - usedHours;

  if (loading) {
    return (
      <div className="ml-[4px] w-full h-[20px]">
        <Spinner small />
      </div>
    );
  }

  switch (selectedUmbrella.billingMode) {
    case UMBRELLA_BILLING_MODE.unlimited:
      return (
        <div
          className="flex gap-[4px] items-center w-full"
          data-testid={struct.adminDash.header.umbrellaMenu.unlimited}
        >
          <span className="font-bold text-[24px] ml-[4px]">∞</span> Equity plan
        </div>
      );
    case UMBRELLA_BILLING_MODE.prepaid:
      return (
        <div
          className="flex flex-col w-full gap-[4px]"
          data-testid={struct.adminDash.header.umbrellaMenu.prepaid}
        >
          <div className="w-full h-[8px] rounded-[8px] bg-[#DCE2F1]">
            <div
              className="h-full bg-navy-900 rounded-[8px] rounded-r-none"
              style={{
                width: `${(usedHours / totalHours) * 100}%`
              }}
            />
          </div>
          <div className="flex items-center">
            <span
              className="font-bold mr-[4px]"
              data-testid={struct.adminDash.header.umbrellaMenu.numberHours}
            >
              {Math.round(remainingHours)}
            </span>
            Hours remaining
          </div>
        </div>
      );
    case UMBRELLA_BILLING_MODE.postpaid:
    default:
      return (
        <div
          className="flex gap-[4px] items-center w-full ml-[4px]"
          data-testid={struct.adminDash.header.umbrellaMenu.postpaid}
        >
          <span
            className="font-bold"
            data-testid={struct.adminDash.header.umbrellaMenu.numberHours}
          >
            {usedHours}
          </span>{" "}
          Hours used
        </div>
      );
  }
};

const displayName = (name: string) =>
  name.replace(/\b[Pp]arent\b/, "All schools");

const Icon: React.FC<{ parent?: boolean }> = ({ parent }) => {
  const props = {
    className: "w-[24px] h-[24px] flex-shrink-0 fill-current text-navy-900",
    "aria-hidden": true
  };
  return parent ? <ParentSchoolIcon {...props} /> : <SchoolIcon {...props} />;
};
