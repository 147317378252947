import * as React from "react";

import { SubjectGroup } from "~/declarations/models/Subject";
// import { UmbrellaAccount } from "~/declarations/models/UmbrellaAccount";

export interface AccountLayoutContextProps {
  mobileView: boolean;
  sideMenuMinimized: boolean;
  filtersDrawerOpen: boolean;
  subjects: {
    data: SubjectGroup[] | undefined;
    loading: boolean;
    error: Error | undefined;
  };
  // selectedUmbrella?: UmbrellaAccount;
  onTriggerViewSideMenu?: () => void;
  // onSelectUmbrellaAccount?: (umbrellaAccount: UmbrellaAccount) => void;
  onToggleFiltersDrawer?: () => void;
}

const AccountLayoutContext = React.createContext<AccountLayoutContextProps>({
  mobileView: false,
  subjects: {
    data: undefined,
    loading: false,
    error: undefined
  },
  sideMenuMinimized: true,
  filtersDrawerOpen: false
});

export default AccountLayoutContext;
